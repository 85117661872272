// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#arrowIcon {
    color: white;
    cursor: pointer;
}

#arrowIcon:hover {
    scale: 1.3;
    transition: all 0.2s ease-in-out;
}

#TitleIngate {
    font-weight: 600;
    color: #4BA5FF;
    font-size: 70%;
}

#DividerCss {
    margin: 2% 0 2% 0;
    border: solid 1px #c6c6c6;
    border-radius: 50px;
}

#text-color {
    color: #4BA5FF;
}

/* #demo-simple-select {
  display: flex;
  padding-bottom: 0;
} */`, "",{"version":3,"sources":["webpack://./src/components/ModalTransaction/ModalTransaction.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;;;GAGG","sourcesContent":["#arrowIcon {\n    color: white;\n    cursor: pointer;\n}\n\n#arrowIcon:hover {\n    scale: 1.3;\n    transition: all 0.2s ease-in-out;\n}\n\n#TitleIngate {\n    font-weight: 600;\n    color: #4BA5FF;\n    font-size: 70%;\n}\n\n#DividerCss {\n    margin: 2% 0 2% 0;\n    border: solid 1px #c6c6c6;\n    border-radius: 50px;\n}\n\n#text-color {\n    color: #4BA5FF;\n}\n\n/* #demo-simple-select {\n  display: flex;\n  padding-bottom: 0;\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
